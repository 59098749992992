import React from 'react'
import styled from 'styled-components'


const ListElement=({img, content}) => {
    return(
        <StyledDiv>
            <figure>
                {img}
            </figure>
            <StyledContent>
                {content}
            </StyledContent>
        </StyledDiv>

    );
}
export default ListElement

const StyledDiv = styled.div`
    width:90%;
    margin-left:10%;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
`;

const StyledContent = styled.p`
padding-left: 50px;
`;

