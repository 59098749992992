import React from 'react'

import styled from 'styled-components'
import {Container, Row, Col} from 'react-bootstrap'
import {StyledContainer, ContentWrapper, ImageTextContainer, BannerImg} from './layoutStyles'

import Seo from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'
import MainMenu from '../components/MainMenu'
import RzetelnaFirma from '../components/RzetelnaFirma/RzetelnaFirma'


export default ({headerPhoto, prefooterElement, children}) => {

  return (
    <StyledContainer>
      <Container fluid className="p-0">
        <Seo/>
        <Row noGutters>
          <Col>
            <Header />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <ImageTextContainer>
              <BannerImg>
                {headerPhoto}
              </BannerImg>
              <MainMenu/>
            </ImageTextContainer>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mx-0">
          <Col lg={8}>
            <ContentWrapper>
              {children}
            </ContentWrapper>
          </Col>
        </Row>
        <Row noGutters>
          <StyledCol className="d-flex justify-content-center">
            <Col lg={8}>
              <StyledHeader>
                Naprawa i reklamacje posadzek
              </StyledHeader>
              <StyledContent>
                Rodzaj i koszt usługi serwisowej jest ustalany indywidualnie w zależności 
                od rodzaju uszkodzonej powierzchni oraz czasu pracy. Zalecamy kontakt 
                telefoniczny w celu zrealizowania usługi.<br/>
                W przypadku reklamacji naszej posadzki, na szczegóły usługi mogą wpłynąć 
                wcześniejsze ustalenia z klientem. Z tego powodu wszelkie nasze zastrzeżenia 
                względem wylewania niektórych rodzajów posadzek na danym fundamencie należy 
                potraktować z odpowiednią rozwagą.
              </StyledContent>
              <StyledHeader>
                Cennik
              </StyledHeader>
              <StyledContent>
                W związku z różnorodnością wykonywanych posadzek w celach ustalenia ceny
                zalecamy skontaktować się z nami telefonicznie, bądź mailowo.
                Cena wykonywanej usługi jest zależna od ilości metrów, rodzaju użytych
                materiałów, wcześniejszej powierzchni/podkładu posadzkowego oraz
                przeznaczenia posadzki.
              </StyledContent>
            </Col>
          </StyledCol>
        </Row>
        <Prefooter>
          <Row className="justify-content-center">
            <Col className="d-flex" xs={12} xl={7}>
              {prefooterElement}
            </Col>
            <Col xs={12} xl={5}>
              <RzetelnaFirma/>
            </Col>
          </Row>
        </Prefooter>
        <Footer />
      </Container>
    </StyledContainer>
  )
}

const Prefooter = styled.div`
  background-color: white;
  color: black;
  padding-top: 7%;
  padding-bottom: 10%;
  padding-left: 6%;
  padding-right: 6%;
`;

const StyledHeader = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;

const StyledCol = styled(Col)`
  background-color:var(--blue);
  padding-top: 80px;
`;

const StyledContent = styled.p`
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding-bottom: 80px;
  text-align: justify;
`;